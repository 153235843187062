export default class NavigationModel {
    constructor(
        id = null,
        title = "",
        type = undefined,
        external_url = "",
        icon = "",
        class_field = "",
        description = "",
        path = "",
        enabled = true,
        parent = "",
        organization = "",
    ) {
        this.id = id;
        this.title = title;
        this.type = type;
        this.icon = icon;
        this.external_url = external_url;
        this.class_field = class_field;
        this.description = description;
        this.path = path;
        this.enabled = enabled;
        this.parent = parent;
        this.organization = organization;
    }

    update = obj => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                this[key] = obj[key];
            }
        }
    };
}
