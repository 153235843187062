import * as React from "react";
import {IObservableArray} from "mobx";
import {Dispatch, SetStateAction} from "react";

export const enum conditionType {
    ADD_TOPLINK,
    ADD_HEADING,
    ADD_SUBLINK,
    EDITING,
}

export const enum currentViewType {
    heading,
    traditional,
    list,
    icons,
    external,
    existing,
    initial,
    new,
    calendar,
    document,
    folder,
    quicklink,
    quicklinkInternal,
    sharedfile,
    shortcut,
    shortcutExternal,
    shortcutInternal,
    loadingInternal,
}

declare interface modalObjType {
    type: string,
    condition: conditionType,
    nodeInfo: Navigation,
    navigation: Navigation[] & IObservableArray,
    parent_id?: string,
    terrace_id?: number,
    initialView?: currentViewType,
    navigationUpdater: (n: Navigation) => void,
    navigationCreator: (n: Navigation) => Navigation,
}

declare interface ViewProps {
    node: Navigation,
    setNode: React.SetStateAction<any>,
    handleFlowChange: (type: currentViewType) => void,
    condition: conditionType,
    setNewOrganizationType: Dispatch<SetStateAction<OrganizationType>>,
    newOrganizationType: OrganizationType,
    initialView?: currentViewType,
    blockObj: AnyOrganizationBlockObj | null,
    setBlockObj: React.SetStateAction<any>,
    newOrganizationPublished: "1" | "0",
    setNewOrganizationPublished: Dispatch<SetStateAction<"1" | "0">>
}
