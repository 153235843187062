import {ITerraceTypes} from "./Terrace";
import styles from "./styles/_BlockTitleCS.module.scss";
import ClickableLink from "../../utilities/ClickableLink";
import {withoutLocaleInURI} from "../../../internationalization/i18nURI";
import ImageComponent from "../../utilities/ImageComponent";
import FontAwesome from "../../utilities/FontAwesome";
import {observer} from "mobx-react";
import URI from 'urijs';
import classNames from "classnames";
import {ReactElement} from "react";

const ViewMoreButton = observer((props: {
    terraceType?: ITerraceTypes,
    organizationId?: string,
    text?: string | ReactElement,
    className?: string,
    url?: string,
}) => {
    const params = {};
    if (props.terraceType) {
        params["type"] = props.terraceType;
    }
    if (props.organizationId) {
        params["orgId"] = props.organizationId;
    }

    const url = props.url ? props.url : new URI(withoutLocaleInURI("/feed")).setSearch(params).toString();

    const viewMoreButtonClassName = classNames({
        [styles.viewMoreButtonContainer]: true,
        [props.className || ""]: props.className,
    })

    return <div className={viewMoreButtonClassName}>
        <ClickableLink
            href={url}>
            <span>{props.text || "View More"}</span>
        </ClickableLink>
    </div>
})

export default ViewMoreButton;
